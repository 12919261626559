<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-overlay
          :show="showLoading"
          rounded="sm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  md="6"
                  lg="4"
                >
                  <div class="amount-payable checkout-options">
                    <b-card title="Текущая настройка">

                      <ul class="list-unstyled price-details">
                        <li class="price-detail mb-1">
                          <div class="details-title">
                            Период оптимизации
                          </div>
                          <div v-if="results != null" class="detail-amt">
                            <strong>{{ results[0] != null && results[0].period != null ? results[0].period : 'не задано' }}</strong>
                          </div>
                        </li>
                        <li class="price-detail">
                          <div class="details-title">
                            День отчета
                          </div>
                          <div class="detail-amt">
                            <strong>{{ results[0] != null && results[0].weekday != null ? results[0].weekday : 'не задано' }}</strong>
                          </div>
                        </li>
                      </ul>
                      <hr>
                      <ul class="list-unstyled price-details">
                        <li class="price-detail">
                          <div class="details-title">
                            Показатели
                          </div>
                          <div class="detail-amt font-weight-bolder">
                            {{ results[0] != null && results[0].indicators != null ? results[0].indicators : 'не задано' }}
                          </div>
                        </li>
                      </ul>

                    </b-card>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  lg="8"
                >
                  <b-card-actions
                    img-top
                    no-body
                    action-collapse
                    title="Изменение настроек сервиса рекомендаций"
                  >
                    <b-card-header class="flex-column align-items-start">
                      <b-card-title>Параметры настройки</b-card-title>
                      <b-card-text class="text-muted mt-25">
                        Добавьте настройки, чтобы работать с рекомендациями
                      </b-card-text>
                    </b-card-header>
                    <b-card-body>
                      <b-row>

                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group label="Период оптимизации">
                            <validation-provider
                              #default="{ errors }"
                              name="period"
                              rules="required"
                            >
                              <b-form-radio-group
                                id="period-elem"
                                v-model="periodSelected"
                                :options="periodOptions"
                                :state="errors.length > 0 ? false:null"
                                class="mb-1"
                                button-variant="outline-primary"
                                buttons
                                name="period"
                              />
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="weekday"
                            rules="required"
                          >
                            <b-form-group label="В какой день недели?">
                              <b-form-select
                                id="weekdays"
                                v-model="weekday"
                                name="weekday"
                                :options="weekdays"
                                :state="errors.length > 0 ? false:null"
                                class="mb-1"
                              />
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          md="12"
                        >
                          <validation-provider
                            v-if="options.length > 0"
                            #default="{ errors }"
                            name="indicators"
                            rules="required"
                          >
                            <b-form-group label="Показатели">
                              <b-form-radio-group
                                id="indicators"
                                v-model="selected"
                                name="indicators"
                                button-variant="outline-primary"
                                buttons
                                :state="errors.length > 0 ? false:null"
                                :options="options"
                              />

                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>

                            </b-form-group>
                          </validation-provider>
                          <b-form-group v-if="options.length === 0"  label="Показатели">
                            <span class="text-danger">На вашем проекте нет заданных KPI</span>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                size="sm"
                                class="ml-1"
                                @click="routeToProjectSettings"
                            >
                              Настроить ключевые показатели
                            </b-button>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mt-1"
                            type="submit"
                            :disabled="checkProperties"
                            @click.prevent="validationForm"
                          >
                            Сохранить настройку
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card-actions>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-col>
      <app-tour :steps="steps" />
    </b-row>
  </section>
</template>
<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCol,
  BRow,
  BForm,
  BTableLite,
  BFormSelect,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormGroup,
  BCardText,
  BFormInvalidFeedback,
  BFormInput,
  BCardSubTitle,
  BLink,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import { Russian } from 'flatpickr/dist/l10n/ru'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    AppTour,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BCardActions,
    vSelect,
    BForm,
    BCardText,
    BLink,
    BCardSubTitle,
    BFormSelect,
    BCardHeader,
    BTableLite,
    BCardBody,
    BOverlay,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
  },
  data() {
    return {
      steps: [
        {
          target: '#period-elem',
          header: {
            title: 'Период формирования отчета',
          },
          content: 'Выберите период, за который необходимо формировать отчет рекомендаций',
        },
        {
          target: '#weekdays',
          header: {
            title: 'День недели',
          },
          content: 'Выберите день на неделе, когда вы хотите получать отчет рекомендаций',
        },
        {
          target: '#indicators',
          header: {
            title: 'Выбор показателей',
          },
          content: 'Выберите KPI, которые хотите отслеживать. '
              + 'Вы должны добавить целевые KPI в настройках проекта и задать их значения',
        },
      ],
      required,
      weekday: 1,
      weekdays: [
        { value: 1, text: 'Понедельник' },
        { value: 2, text: 'Вторник' },
        { value: 3, text: 'Среда' },
        { value: 4, text: 'Четверг' },
        { value: 5, text: 'Пятница' },
        { value: 6, text: 'Суббота' },
        { value: 0, text: 'Воскресенье' },
      ],
      fields: [
        {
          key: 'indicators',
          label: 'Показатели',
        },
        {
          key: 'period',
          label: 'Период оптимизации',
        },
        {
          key: 'weekday',
          label: 'В какой день?',
        },
        {
          key: 'period',
          label: 'День оптимизации',
        },
      ],
      results: [],
      dateDefault: dateNow,
      rangeDataFilter: [dateNowPrev, dateNow],
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'Y-m-d',
      },
      periodSelected: null,
      periodOptions: [
        { text: 'День', value: 'day' },
        { text: 'Неделя', value: 'week' },
        { text: 'Месяц', value: 'month' },
      ],
      showLoading: false,
      selected: null,
      options: [],
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    activeProject() {
      return this.$store.state.project.projects.find(c => c.id === this.activeProjectId)
    },
    checkProperties() {
      return !(this.periodSelected != null && this.selected !== null)
    },
  },
  watch: {
    activeProjectId(newState, oldState) {
      this.showLoading = true
      this.parseKeyIndicators()
      this.showSettings()
    },
  },
  mounted() {
    if (this.activeProjectId) {
      this.showLoading = true
      this.parseKeyIndicators()
      this.showSettings()
    }
  },
  created() {
  },
  methods: {
    routeToProjectSettings() {
      if (this.activeProjectId != null) {
        this.$router.push({
          name: 'projects-detail',
          params: {
            projectId: this.activeProjectId,
            hash: '#settings',
          },
        })
      }
    },
    parseKeyIndicators() {
      const { keyIndicators } = this.activeProject
      if (keyIndicators != null) {
        keyIndicators.split(',').forEach(item => {
          const property = item.split('=')
          if (property != null) {
            if (property[0] !== '') {
              this.options.push(property[0])
            }
          }
        })
      }
    },
    showSettings() {
      this.results = []
      this.$http.get(`recommendation/show?projectId=${this.activeProjectId}`)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // TO-DO: NEED refactoring
          const responseData = response.data

          if (responseData != null && responseData !== '') {
            const weekDayText = this.weekdays
              .find(c => c.value === responseData.weekday)
              .text

            const obj = {
              indicators: responseData.indicators,
              period: responseData.period,
              projectId: responseData.projectId,
              weekday: weekDayText,
              dateRange: responseData.dateRange,
            }

            this.results = []
            this.results.push(obj)
            this.showLoading = false
          }
          this.showLoading = false
        })
    },
    validationForm() {
      this.showLoading = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm('Вы уверены, что хотите поменять настройку? Это приведет к изменениям отчета рекомендации', {
              title: 'Изменение настройки рекомендации',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Да, уверен',
              cancelTitle: 'Нет',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                let periodSelected
                if (this.periodSelected != null) {
                  periodSelected = this.periodOptions
                    .find(c => c.value === this.periodSelected)
                    .text
                }

                const weekDayText = this.weekdays
                  .find(c => c.value === this.weekday)
                  .text

                const tempDatas = []
                tempDatas.push(this.selected)

                const obj = {
                  indicators: tempDatas.join(','),
                  period: periodSelected,
                  projectId: this.activeProjectId,
                  weekday: weekDayText,
                }

                this.results = []
                this.results.push(obj)

                const objSave = {
                  indicators: tempDatas.join(','),
                  period: periodSelected,
                  projectId: this.activeProjectId,
                  weekday: this.weekday,
                }

                this.$http.post('recommendation/save', objSave)
                // eslint-disable-next-line no-unused-vars
                  .then(response => {
                    // eslint-disable-next-line
                      this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Настройка сохранена',
                        icon: 'SettingsIcon',
                        variant: 'success',
                      },
                    })
                    this.showLoading = false
                  })
              } else {
                this.showLoading = false
              }
            })
        }
      })
    },
    saveSettings() {
    },
  },
}
</script>

<style scoped>
.no-content {
  margin:0 auto;
}

.price-detail {
  display: flex;
  justify-content: space-between;
}
</style>

<style lang="scss">
@import '~@core/scss/base/pages/projects-list.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
